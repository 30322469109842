<ng-container>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="20px"
    class="dialog-title"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <svg-icon
        [src]="'assets/icons/not-allowed-light.svg'"
        fxLayout="row"
        fxLayoutAlign="center center"
        [svgStyle]="{ 'height.px': 24 }"
      ></svg-icon>
      <span class="dialog-name">
        <h3>Aucun abonnement trouvé pour ce site.</h3></span
      >
    </div>

    <div fxlayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      @if ((user$ | async)?.sites) {

      <mat-form-field appearance="fill" class="select-current-site">
        <mat-select
          [(value)]="selectedSite"
          (selectionChange)="updateSelectedSite($event)"
          [compareWith]="compareObjects"
        >
          <mat-select-trigger>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
              class="mat-toolbar-compagny"
            >
              <svg-icon
                class="icon-chip"
                [src]="'assets/icons/building-hospital-bulk.svg'"
                fxLayout="row"
                fxLayoutAlign="center center"
              ></svg-icon>

              @for (site of (user$ | async)?.sites; track site) {
              @if(site.id==selectedSite) {
              <span> {{ site.name }}</span>
              } }
            </div>
          </mat-select-trigger>
          @for (site of (user$ | async)?.sites; track site) {

          <mat-option [value]="site">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
              class="mat-toolbar-compagny"
            >
              <svg-icon
                class="icon-chip"
                [src]="'assets/icons/building-hospital-bulk.svg'"
                fxLayout="row"
                fxLayoutAlign="center center"
              ></svg-icon>

              <span> {{ site.name }}</span>
            </div>
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      }
      <!-- / Site -->

      <button mat-stroked-button (click)="disconnect()" type="button">
        Me déconnecter
      </button>
    </div>
  </div>

  <mat-dialog-content
    class="mat-typography"
    fxLayout="column"
    fxLayoutAlign="space-between center"
    fxLayoutGap="30px"
  >
    <stripe-pricing-table
      [attr.pricing-table-id]="PRICING_TABLE"
      [attr.publishable-key]="STRIPE_PUBLIC_KEY"
      [attr.client-reference-id]="CLIENT_REFERENCE_ID"
      [attr.customer-email]="CUSTOMER_EMAIL"
      style="width: 100%"
    >
    </stripe-pricing-table>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="dialog-actions"
    >
      <!-- <button mat-flat-button color="primary" (click)="disconnect()">
        Me déconnecter
      </button> -->
    </div>
  </mat-dialog-content>
</ng-container>
