import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { environment as env } from '@env';
@Component({
  selector: 'app-site-missing-subscription',
  templateUrl: './site-missing-subscription.component.html',
  styleUrl: './site-missing-subscription.component.scss',
})
export class SiteMissingSubscriptionComponent {
  readonly dialogRef = inject(MatDialogRef<SiteMissingSubscriptionComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);

  static alreadyExists: boolean = false;

  user$ = this.authService.user;

  CLIENT_REFERENCE_ID: string = '';
  CUSTOMER_EMAIL: string = '';
  STRIPE_PUBLIC_KEY: string = env.stripePublicKey;
  PRICING_TABLE = env.stripePricingTableId;

  selectedSite: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.CLIENT_REFERENCE_ID = this.data['siteId'];
    this.CUSTOMER_EMAIL = this.data['email'];
    if (SiteMissingSubscriptionComponent.alreadyExists) {
      this.dialogRef.close();
    } else {
      SiteMissingSubscriptionComponent.alreadyExists = true;
    }
  }

  ngOnInit(): void {
    this.selectedSite = this.data['siteId'];
  }

  disconnect(): void {
    this.authService.signOut();
    SiteMissingSubscriptionComponent.alreadyExists = false;
    this.dialogRef.close();
  }

  updateSelectedSite(event: any) {
    this.selectedSite = event.value.id;
    let urlFragments = this.router.url.split('/');
    urlFragments[urlFragments.indexOf('sites') + 1] = this.selectedSite;
    let urlString = '/sites/' + this.selectedSite + '/home';
    this.router.navigateByUrl(urlString, {
      onSameUrlNavigation: 'reload',
    });
    SiteMissingSubscriptionComponent.alreadyExists = false;
    this.dialogRef.close();
  }

  compareObjects(o1: any, o2: any) {
    if (o1.id == o2) {
      return true;
    } else {
      return false;
    }
  }
}
