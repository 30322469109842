import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  lang = 'fr';
  constructor(
    private _snackBar: MatSnackBar,
    private cookieService: SsrCookieService
  ) {
    this.lang = this.cookieService.get('current-language') || 'fr';
  }

  /**
   * Triggers the display of a pop up message
   * @param message Message display ex. 'Hello world !'
   * @param action  Message display in close btn
   */
  openSnackBar(
    message: string,
    action: string = 'Fermer',
    duration: number = 3
  ) {
    this._snackBar.open(message, action, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: duration * 1000,
      panelClass: ['snackbar-theme'],
    });
  }

  displayErrorMessage(error: any) {
    var error_message = '';

    // If Current Language isn't English -> Traduction
    if (this.lang !== 'en') {
      error_message = this.erroMessageTraduction[error.statusCode][this.lang]
        ? this.erroMessageTraduction[error.statusCode][this.lang]
        : error.statusCode + ' - untranslated error';
    } else {
      // Else if english -> return error message
      error_message += ' - ' + error.message.toString();
    }

    return error_message;
  }

  erroMessageTraduction: any = {
    // Bad Request
    400: {
      fr: 'Mauvaise requête',
    },
    // Unauthorized
    401: {
      fr: 'Accès non autorisé',
    },
    // Forbidden
    403: {
      fr: 'Accès interdit',
    },
    // Not Found
    404: {
      fr: 'Non trouvé',
    },
    // Item already existing
    409: {
      fr: 'Ce dispositif est déjà enregistré sur ce site',
    },
    // Internal Server Error
    500: {
      fr: 'Erreur de serveur interne',
    },
  };
}
