import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'app-site-subscription-issue',
  templateUrl: './site-subscription-issue.component.html',
  styleUrl: './site-subscription-issue.component.scss',
})
export class SiteSubscriptionIssueComponent {
  readonly dialogRef = inject(MatDialogRef<SiteSubscriptionIssueComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);

  mailTo: string = 'mailto:contact@check-op.com';

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  disconnect(): void {
    this.authService.signOut();
    this.dialogRef.close();
  }
}
