import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Site } from '../models/site.model';

import { TranslateService } from '@core/services/translate.service';
import { Observable } from 'rxjs';

// Route of Model ( All / One ... )
const routes = {
  sites: '/sites',
  site: (id: string) => `/sites/${id}`,
  addUsers: (id: string) => `/sites/${id}/add-users`,
  removeUsers: (id: string) => `/sites/${id}/remove-users`,
  sitesForHolding: (id: string) => `/sites/holding/${id}`,
};

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  getAll(): Observable<Site[]> {
    return this.apiService.get(routes.sites);
  }

  getAllForHolding(holdingId: string): Observable<Site[]> {
    return this.apiService.get(routes.sitesForHolding(holdingId));
  }

  getSingle(id: string): Observable<Site> {
    return this.apiService.get(routes.site(id));
  }

  create(site: Site): Observable<Site> {
    return this.apiService.post(routes.sites, site);
  }

  update(id: string, site: Site): Observable<Site> {
    return this.apiService.patch(routes.site(id), site);
  }

  addUsersToSite(id: string, users: any): Observable<Site> {
    return this.apiService.patch(routes.addUsers(id), users);
  }

  removeUsersOfSite(id: string, users: any): Observable<Site> {
    return this.apiService.patch(routes.removeUsers(id), users);
  }

  activateSite(siteId: string) {
    return this.apiService.patch(`${routes.site(siteId)}/activate`, {});
  }

  deactivateSite(siteId: string) {
    return this.apiService.patch(`${routes.site(siteId)}/deactivate`, {});
  }

  getBillingPortalSessionUrl(siteId: string): Observable<any> {
    if (!siteId) {
      return new Observable((observer) => {
        null;
      });
    }
    return this.apiService.get(`${routes.site(siteId)}/billing-portal-session`);
  }

  getNbOfReferencesForSite(siteId: string): Observable<number> {
    return this.apiService.get(`${routes.site(siteId)}/references/count`);
  }

  getNbOfSeatsForSite(siteId: string): Observable<number> {
    return this.apiService.get(`${routes.site(siteId)}/users/count`);
  }
}
