import { ITSELF as r, createInterpreter as t } from "@ucast/core";
function n(r, t, n) {
  for (let e = 0, o = r.length; e < o; e++) if (0 === n(r[e], t)) return !0;
  return !1;
}
function e(r, t) {
  return Array.isArray(r) && Number.isNaN(Number(t));
}
function o(r, t, n) {
  if (!e(r, t)) return n(r, t);
  let o = [];
  for (let e = 0; e < r.length; e++) {
    const u = n(r[e], t);
    void 0 !== u && (o = o.concat(u));
  }
  return o;
}
function u(r) {
  return (t, n, e) => {
    const o = e.get(n, t.field);
    return Array.isArray(o) ? o.some(n => r(t, n, e)) : r(t, o, e);
  };
}
const c = (r, t) => r[t];
function i(r, t, n) {
  const e = t.lastIndexOf(".");
  return -1 === e ? [r, t] : [n(r, t.slice(0, e)), t.slice(e + 1)];
}
function f(t, n, e = c) {
  if (n === r) return t;
  if (!t) throw new Error(`Unable to get field "${n}" out of ${String(t)}.`);
  return function (r, t, n) {
    if (-1 === t.indexOf(".")) return o(r, t, n);
    const e = t.split(".");
    let u = r;
    for (let r = 0, t = e.length; r < t; r++) if (u = o(u, e[r], n), !u || "object" != typeof u) return u;
    return u;
  }(t, n, e);
}
function s(r) {
  return (t, n) => f(t, n, r);
}
function a(r, t) {
  return r === t ? 0 : r > t ? 1 : -1;
}
function l(r, n = {}) {
  return t(r, Object.assign({
    get: f,
    compare: a
  }, n));
}
const p = (r, t, {
    interpret: n
  }) => r.value.some(r => n(r, t)),
  g = (r, t, n) => !p(r, t, n),
  m = (r, t, {
    interpret: n
  }) => r.value.every(r => n(r, t)),
  y = (r, t, {
    interpret: n
  }) => !n(r.value[0], t),
  b = (r, t, {
    compare: e,
    get: o
  }) => {
    const u = o(t, r.field);
    return Array.isArray(u) && !Array.isArray(r.value) ? n(u, r.value, e) : 0 === e(u, r.value);
  },
  A = (r, t, n) => !b(r, t, n),
  d = u((r, t, n) => {
    const e = n.compare(t, r.value);
    return 0 === e || -1 === e;
  }),
  h = u((r, t, n) => -1 === n.compare(t, r.value)),
  j = u((r, t, n) => 1 === n.compare(t, r.value)),
  w = u((r, t, n) => {
    const e = n.compare(t, r.value);
    return 0 === e || 1 === e;
  }),
  _ = (t, n, {
    get: o
  }) => {
    if (t.field === r) return void 0 !== n;
    const [u, c] = i(n, t.field, o),
      f = r => !!r && r.hasOwnProperty(c) === t.value;
    return e(u, c) ? u.some(f) : f(u);
  },
  v = u((r, t) => "number" == typeof t && t % r.value[0] === r.value[1]),
  x = (t, n, {
    get: o
  }) => {
    const [u, c] = i(n, t.field, o),
      f = r => {
        const n = o(r, c);
        return Array.isArray(n) && n.length === t.value;
      };
    return t.field !== r && e(u, c) ? u.some(f) : f(u);
  },
  O = u((r, t) => "string" == typeof t && r.value.test(t)),
  N = u((r, t, {
    compare: e
  }) => n(r.value, t, e)),
  $ = (r, t, n) => !N(r, t, n),
  q = (r, t, {
    compare: e,
    get: o
  }) => {
    const u = o(t, r.field);
    return Array.isArray(u) && r.value.every(r => n(u, r, e));
  },
  z = (r, t, {
    interpret: n,
    get: e
  }) => {
    const o = e(t, r.field);
    return Array.isArray(o) && o.some(t => n(r.value, t));
  },
  E = (r, t) => r.value.call(t);
var M = Object.freeze({
  __proto__: null,
  or: p,
  nor: g,
  and: m,
  not: y,
  eq: b,
  ne: A,
  lte: d,
  lt: h,
  gt: j,
  gte: w,
  exists: _,
  mod: v,
  size: x,
  regex: O,
  within: N,
  nin: $,
  all: q,
  elemMatch: z,
  where: E
});
const S = Object.assign({}, M, {
    in: N
  }),
  U = l(S);
export { q as all, S as allInterpreters, m as and, a as compare, s as createGetter, l as createJsInterpreter, z as elemMatch, b as eq, _ as exists, f as getObjectField, i as getObjectFieldCursor, j as gt, w as gte, U as interpret, h as lt, d as lte, v as mod, A as ne, $ as nin, g as nor, y as not, p as or, O as regex, x as size, E as where, N as within };
