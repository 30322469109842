<ng-container>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="20px"
    class="dialog-title"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <svg-icon
        [src]="'assets/icons/not-allowed-light.svg'"
        fxLayout="row"
        fxLayoutAlign="center center"
        [svgStyle]="{ 'height.px': 24 }"
      ></svg-icon>
      <span class="dialog-name"><h3>Problème avec votre abonnement</h3></span>
    </div>

    <button mat-stroked-button (click)="disconnect()" type="button">
      Me déconnecter
    </button>
  </div>

  <mat-dialog-content
    class="mat-typography"
    fxLayout="column"
    fxLayoutAlign="space-between center"
    fxLayoutGap="30px"
  >
    <div> </div>
    <p>
      Un problème a été détecté avec votre abonnement. Veuillez contacter
      l'équipe de Check'Op pour plus d'information.
    </p>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="dialog-actions"
    >
      <a mat-flat-button color="primary" [href]="mailTo">Contacter</a>
    </div>
  </mat-dialog-content>
</ng-container>
