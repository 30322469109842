import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/data/models/user.model';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { ApiService } from '../../data/api/api.service';

import { MatDialog } from '@angular/material/dialog';
import { Permission } from '@app/data/models/permission.model';
import { SiteService } from '@app/data/services/site.service';
import { SiteMissingSubscriptionComponent } from '@app/shared/dialog/site-missing-subscription/site-missing-subscription.component';
import { SiteSubscriptionIssueComponent } from '@app/shared/dialog/site-subscription-issue/site-subscription-issue.component';
import { SnackbarService } from '@app/shared/services/snackbar.service';
import { Ability, AbilityBuilder } from '@casl/ability';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly dialog = inject(MatDialog);
  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private cookieService: SsrCookieService,
    private siteService: SiteService,
    private snackbarService: SnackbarService,
    private ability: Ability
  ) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  isSignedIn(): boolean {
    return this.cookieService.check('active_session');
  }

  isAssociatedToHolding(): boolean {
    if (this.userValue === null) {
      return false;
    }
    return (this.userValue.role?.level ?? 2) <= 1; // Admin checkop/root
    // return this.cookieService.check('current-holding');
  }

  loadAppSession() {
    if (this.cookieService.check('active_session')) {
      this.refreshToken().subscribe();
    } else {
      this.userSubject.next(null);
    }
  }

  /**
   * Method allowing a user to register as a new client.
   * @param user
   * @param holding
   * @param site
   * @returns Observable
   */
  registerClient(user: any, holding: any, site: any): Observable<any> {
    return this.apiService.post('/auth/register-client', {
      user: user,
      holding: holding,
      site,
    });
  }

  fulfillCheckout(sessionId: string): Observable<any> {
    return this.apiService.post(
      `/auth/fulfill-checkout/${sessionId}?use-cookies=true`
    );
  }

  /**
   * Method allowing a user to connect by entering his username and password.
   * @param username
   * @param password
   * @returns Observable
   */
  signIn(username: string, password: string): Observable<any> {
    this.cookieService.set('active_session', Date.now().toString(), {
      sameSite: 'Strict',
      path: '/',
      secure: true,
      expires: 90,
    });
    return this.apiService
      .post('/auth/signin?use-cookies=true', {
        username: username,
        password: password,
      })
      .pipe(
        map((data: any) => {
          // this.userSubject.next(undefined);
          this.fetchUserProfileAndPermissions();
        }),
        catchError((error) => {
          this.cookieService.delete('active_session', '/');
          this.cookieService.delete('permissions', '/');
          return error;
        })
      );
  }

  /**
   * Method allowing a user to disconnect from the application
   */
  signOut() {
    this.cookieService.deleteAll();
    this.ability.update([]);
    this.router.navigate(['/auth']); // Redirigez vers la page de connexion après la déconnexion.
  }

  /**
   * @returns true or false depending on the success of the refresh
   */
  refreshToken(): Observable<boolean> {
    return this.apiService.get('/auth/refresh?use-cookies=true').pipe(
      map((data: any) => {
        this.fetchUserProfileAndPermissions();
        return true;
      }),
      catchError((error) => {
        this.signOut();
        return Promise.resolve(false);
      })
    );
  }

  fetchUserProfileAndPermissions() {
    this.apiService.get('/auth/profile').subscribe({
      next: (user: User) => {
        this.userSubject.next(user);
        this.cookieService.set('active_session', Date.now().toString(), {
          sameSite: 'Strict',
          path: '/',
          secure: true,
          expires: 90,
        });

        // Save current Site ID into cookies if none present
        if (
          !this.cookieService.check('current-site') &&
          user.sites &&
          user.sites.length > 0
        ) {
          console.log('Updating current Site ID because none was found');
          this.updateCurrentSiteCookie(user.sites[0]);
        }

        // Save current Holding ID into cookies
        if (user.holding) {
          this.setCurrentHoldingCookie(user.holding);
        } else {
          this.cookieService.delete('current-holding', '/');
          this.cookieService.delete('current-site', '/');
        }

        // Set up authorisation service (castl) with user's permissions
        if (user.role) {
          if (user.role.permissions.length > 0) {
            this.updateAbility(user.role.permissions);
            this.cookieService.set(
              'permissions',
              JSON.stringify(user.role.permissions),
              { sameSite: 'Strict', secure: true, expires: 90, path: '/' }
            );
          }
        }

        // Redirect user to adequate route if the access is forbidden or the route undefined

        if (
          ![
            'sites',
            'catalog',
            'clients',
            'administration',
            'profile',
          ].includes(this.router.url.split('/')[1])
        ) {
          if (user.role?.level ?? 2 <= 1) {
            this.router.navigate(['/administration/clients']);
          } else {
            this.router.navigate(['/sites', user.sites![0].id, 'home']);
          }
        }
      },
      error: (error: any) => {
        this.router.navigate(['/auth']);
      },
    });
  }

  private checkSiteSubscription(user: User) {
    const siteId = this.getUserCurrentSite();
    if (siteId) {
      this.siteService.getSingle(siteId).subscribe({
        next: (site: any) => {
          if (!site.subscription) {
            // No subscription
            this.dialog.open(SiteMissingSubscriptionComponent, {
              width: `${window.innerWidth * 0.8}px`,
              disableClose: true,
              data: {
                siteId: site.id,
                email: user.email,
              },
            });
          } else {
            if (site.subscription.is_active) {
              // All is good
            } else {
              this.dialog.open(SiteSubscriptionIssueComponent, {
                disableClose: true,
              });
            }
          }
        },
      });
    }
  }

  private updateAbility(permissions: Permission[]) {
    const { can, rules } = new AbilityBuilder(Ability);
    permissions.forEach((permission: Permission) => {
      can(permission.action, permission.subject, permission.condition);
    });
    this.ability.update(rules);
  }

  updateUserPassword(objPassword: {
    old_password: string;
    new_password: string;
  }) {
    return this.apiService.patch(`/auth/update-password`, objPassword);
  }
  sendResetPassword(userEmail: string): Observable<any> {
    return this.apiService.get(`/auth/send-reset-password-email/${userEmail}`);
  }

  /* ------------------------------ USER CURRENT SITE ------------------------------ */

  setCurrentHoldingCookie(holding: any) {
    this.cookieService.set('current-holding', holding.id ?? holding, {
      sameSite: 'Strict',
      path: '/',
      secure: true,
      expires: 90,
    });
  }

  updateCurrentSiteCookie(site: any) {
    this.cookieService.delete('current-site', '/');
    this.cookieService.set('current-site', site.id ?? site, {
      sameSite: 'Strict',
      path: '/',
      secure: true,
      expires: 90,
    });
    this.checkSiteSubscription(this.userValue!);
  }

  getUserCurrentSite(): string {
    return this.cookieService.get('current-site') ?? '';
  }
  /* ------------------------------ USER CURRENT SITE ------------------------------ */

  /* ------------------------------ APP LANGUAGE ------------------------------ */
  setUserAppLanguage(language?: string) {
    const lang = language ?? navigator.language.split('-')[0];
    this.cookieService.set('current-language', lang, {
      sameSite: 'Strict',
      path: '/',
      secure: true,
      expires: 90,
    });
  }

  updateUserAppLanguage(language?: string) {
    const lang = language ?? navigator.language.split('-')[0];
    this.cookieService.set('current-language', lang, {
      sameSite: 'Strict',
      path: '/',
      secure: true,
      expires: 90,
    });
    window.location.reload();
  }

  getUserAppLanguage(): string {
    return this.cookieService.get('current-language');
  }
  checkUserAppLanguage() {
    return this.cookieService.check('current-language');
  }
  /* ------------------------------ / APP LANGUAGE ------------------------------ */
}
